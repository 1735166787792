.container {
  position: fixed;
  left: 16px;
  bottom: 16px;
  width: calc(100% - 32px);
  height: fit-content;
  background: #2f3337;
  color: var(--text-secondary-color);
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  z-index: 1301;

  h4 {
    display: inline;
    padding: 0;
    margin: 8px 0px;
    font-size: var(--md-font);
  }

  p {
    font-size: var(--sm-font);
  }

  a {
    color: var(--text-secondary-color);
    text-decoration: underline !important;
  }

  .header {
    margin-bottom: 4px;
  }

  .cookieIcon {
    font-size: var(--lg-font);
    margin-right: 8px;
  }

  .actionBtn {
    font-size: var(--sm-font);
    font-weight: normal;
  }

  .linkClass {
    cursor: pointer;
  }
}
