@import 'src/styles/import';

.layout {
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
  padding-top: 54px;
  overflow-y: scroll;
  background-color: #eeeeee;

  @include breakpoint(mobile) {
    max-width: 100% !important;
    width: 100% !important;
    height: fit-content;
  }

  @include breakpoint(mobile-tablet) {
    background-color: white;
  }

  .header {
    background-color: white;
    padding: 0 24px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 56px;
    display: flex;
    justify-content: space-between;
    z-index: 99;
    border-bottom: 1px solid #00000012;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.120329);

    @media screen and (min-width: 768px) {
      background-color: #ffffff;
      border-bottom: 1px solid #e5e5e5;
    }

    .iconContainer {
      display: flex;
      align-items: center;
      color: white;

      @include breakpoint(desktop) {
        display: none !important;
      }

      @include breakpoint(tablet) {
        margin-left: -12px;
      }
    }

    a {
      text-decoration: none;
      margin: auto 0;

      img {
        margin-right: 8px;
      }
    }

    .heading {
      flex: 1;
      font-weight: 600;
      font-size: var(--md-font);
      margin: auto;

      @media screen and (min-width: 768px) {
        color: var(--text-primary-color);
      }

      margin: auto 0;
      img {
        -webkit-animation: rotate-in-2-cw 1.5s
          cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s both;
        animation: rotate-in-2-cw 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s
          both;
      }

      @include breakpoint(mobile) {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
      }

      @include breakpoint(mobile-tablet) {
        color: white;
      }
    }

    @-webkit-keyframes rotate-in-2-cw {
      0% {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
      }
      100% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
      }
    }
    @keyframes rotate-in-2-cw {
      0% {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
      }
      100% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
      }
    }

    .roundedSearch {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      max-width: 587px;
      height: 40px;
      border: none;
      border-radius: 32px;
      margin: auto;
      padding: 0 16px;
      background-color: white;
    }

    .actions {
      flex: 1;
      margin: auto 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      @include breakpoint(mobile-tablet) {
        flex: 0 !important;
      }

      .roundedButton {
        float: right;
        background-color: white;
        border-radius: 24px;
        text-transform: capitalize;
        margin-left: 16px;
        font-weight: 600;
        color: var(--text-primary-color);
      }

      .accountButton {
        float: right;
        color: white;
      }

      .logoutButtonMobile {
        float: right;
        color: white;

        @include breakpoint(mobile) {
          display: none;
        }
      }

      .mobile {
        display: none;
      }

      .desktop {
        display: block;
      }
    }

    @include breakpoint(mobile-tablet) {
      background-color: #4285f4;
    }
  }

  .content {
    width: 100%;
    background-color: #e5e5e5;

    @include breakpoint(mobile) {
      background-color: #ffffff;
      min-height: calc(100vh - 124px);
    }
  }

  hr {
    margin: 0 !important;
    color: var(--text-default-light1) !important;
    opacity: 0.25 !important;
  }
}

@include breakpoint(mobile) {
  .layout {
    .header {
      padding: 0;

      .actions {
        .mobile {
          display: block;
        }

        .desktop {
          display: none;
        }
      }
    }
  }
}

.sidePanelContainer {
  width: 100%;
  height: 100%;
  background-color: white;

  .sidePanelHeader {
    padding: 0 16px;
    height: 64px;
    display: flex;
    justify-content: space-between;
    background-color: white;

    @include breakpoint(mobile) {
      padding: 0;
    }

    .sidePanelHeading {
      flex: 1;
      font-weight: 600;
      font-size: var(--lg-font);
      color: var(--text-primary-color);
      margin: auto 0;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.25rem;
    }
  }

  .sidePanelLinks {
    height: calc(100vh - 64px);
    overflow-y: auto;

    a {
      text-decoration: none;
    }

    .link {
      padding: 16px;
      font-weight: 600;
      font-size: var(--md-font);
      color: var(--text-default-color);
      border-bottom: 1px solid rgb(213, 213, 213);
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .active {
      border: none;
      background-color: var(--theme-primary-color);
      color: white;
    }

    .subMenu {
      padding-left: 20px;
    }

    g & .active {
      stroke: white !important;
    }

    path & .active {
      stroke: white !important;
      fill: white !important;
    }

    .icon {
      margin-right: 16px;
      fill: var(--text-default-color);
      font-size: 1.25rem;

      g {
        stroke: var(--text-default-color);
      }
    }
  }
}

.loaderContainer {
  z-index: 9999 !important;
}

.profileImage {
  display: flex;
  height: 40px;
  width: 40px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 16px;
  border-width: 2px;
  border-color: #ffffff73;
  border-style: solid;
}

.hiddenMenuItem {
  visibility: hidden;
  display: none;
}

#menuItemHide {
  display: none;
}

.icon {
  margin-left: 8px;
  margin-right: 16px;
}

.menuProfileIcons {
  font-size: var(--xl-font);
  margin-right: 10px;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 48px;
  max-height: 75px;
  font-size: var(--md-font);
  font-weight: bold;
  text-align: center;
  box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.05);
  z-index: 9999;
  background-color: white;
  // position: absolute;
  bottom: 0;
  width: 100%;

  @media only screen and (max-width: 567px) {
    flex-direction: column;
  }

  span {
    display: flex;

    @media only screen and (max-width: 567px) {
      padding-bottom: 6px;

      .hidden {
        display: none;
      }
    }
  }

  p {
    margin: 0 8px;

    a {
      color: var(--text-default-color) !important;
    }
  }

  @media only screen and (max-width: 567px) {
    font-size: var(--sm-font);

    p {
      margin: 0 4px;
    }
  }

  @media only screen and (max-width: 385px) {
    font-size: 0.6rem;
  }
}

.iconButtonNotificationClass {
  border-radius: 50% !important;
  width: 40px !important;
  height: 40px !important;
  margin-right: 16px !important;

  span {
    color: white !important;
  }
}

.iconMaterialDesign {
  font-size: var(--lg-font) !important;
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.54);
}

.badgeClassStyles {
  span {
    left: -12px;
    top: 4px;
    right: unset;
  }
}

.notificationContainer {
  top: 64px !important;
}

.notificationContainer::before {
  content: url('../../../assets/svg/arrow_drop_up_white_24dp.svg');
  width: 16px;
  height: 16px;
  z-index: 999999;
  position: absolute;
  top: -16px;
  left: 24px;
}

.notificationContainer::-webkit-scrollbar {
  display: none;
}

#accountType {
  padding-top: 6% !important;
}
// href="/u/announcements"
