.badgeWrapper {
  position: relative;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;

  .freeTrialCount {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 10px;
    color: white;
  }
}
