//
// SASS Mixins
// --------------------------------------------------

// Em function
@function em($target, $context: 16px) {
  @return #{$target / $context}em;
}

@mixin breakpoint($point) {
  @if $point == smalldevices {
    @media only screen and (max-width: 320px) {
      @content;
    }
  } @else if $point == medium-mobile {
    @media only screen and (max-width: 420px) {
      @content;
    }
  }@else if $point == mobile {
    @media only screen and (max-width: 767px) {
      @content;
    }
  } @else if $point == tablet {
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      @content;
    }
  } @else if $point == mobile-tablet {
    @media only screen and (max-width: 991px) {
      @content;
    }
  } @else if $point == tablet-landscape {
    @media only screen and (min-width: 768px) and (max-width: 991px) and (orientation: landscape) {
      @content;
    }
  } @else if $point == tablet-lg-desktop {
    @media only screen and (min-width: 768px) and (max-width: 1199px) {
      @content;
    }
  } @else if $point == desktop {
    @media only screen and (min-width: 992px) {
      @content;
    }
  } @else if $point == large-desktop {
    @media only screen and (min-width: 1200px) {
      @content;
    }
  } @else if $point == tablet-desktop {
    @media only screen and (min-width: 768px) {
      @content;
    }
  } @else if $point == projector {
    @media only screen and (min-width: 1300px) {
      @content;
    }
  }
}

@mixin orientation($point) {
  @if $point == portrait {
    @media only screen and (orientation: portrait) {
      @content;
    }
  } @else if $point == landscape {
    @media only screen and (orientation: landscape) {
      @content;
    }
  }
}
