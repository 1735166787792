.footer_link_container {
  z-index: 100;
  padding: 1.5rem 1rem;
}
.footer_link_title {
  font-size: 1.75rem;
  font-weight: 500;
}
.footer_link_heading {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.footer_link_sub_heading {
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 0.75rem;
}

.footer_link_content {
  font-size: 1rem;
  margin-bottom: 0.75rem;
  text-align: justify;
  line-height: 1.5rem;
}

.footer_link_content_blank_item {
  list-style-type: none;
}

.footer_link_content_bullet_item {
  ul {
    li {
      list-style-type: circle;
      ul {
        li {
          list-style-type: square;
        }
      }
    }
  }
}

.link_line_break {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}
